import { useTranslation } from '../../../tools/i18n';
import useUserStore from '../../../services/stores/UserStore';
import CallToActionButton from '../partials/CallToActionButton';
import { useState } from 'react';

export default function AddToWeddingList({ productId, style, colorScheme = 'bg-wedding'}) {
    const { t } = useTranslation();
    const weddingMethods = useUserStore((state) => state.weddingMethods);
    const hasWeddingList = weddingMethods?.hasWeddingList();
    const [isLoading, setIsLoading ] = useState(false);
    const highlighted = weddingMethods.existsInWeddingList(productId);
    return <CallToActionButton
                show={hasWeddingList}
                style={style}
                colorScheme={colorScheme}
                isLoading={isLoading}
                addLines={true}
                onClick={async (e) => {
                    setIsLoading(true);
                    await weddingMethods.toggleWeddingList(productId);
                    setIsLoading(false);
                }}
            >
                {highlighted ? t('remove-from-wedding-list') : t('add-to-wedding-list')}
            </CallToActionButton>
}
